import { Box, Button, Card, Container, styled } from "@mui/material";
import Colors from "../../utils/theme/color";

export const CustomizedCard = styled(Card)(({ theme }) => ({
  borderRadius: 5,
  boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
  width: "95%",
  display: "flex",
  [theme.breakpoints.down("xl")]: {
    display: "flex",
    flexDirection: "row",
  },
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

export const CustomizedDetailsBox = styled(Box)(({ theme }) => ({
  borderRight: `2px solid ${Colors.lightGreyColor}`,
  width: "45%",
  [theme.breakpoints.down("md")]: {
    borderBottom: `2px solid ${Colors.lightGreyColor}`,
    borderRight: "0",
    width: "90%",
    paddingBottom: "1rem",
  },
  [theme.breakpoints.down("lg")]: {
    borderBottom: `2px solid ${Colors.lightGreyColor}`,
    borderRight: "0",
    width: "90%",
    paddingBottom: "1rem",
  },
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.cyanBlueColor,
  width: "92%",
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    width: "85%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "85%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const CloseButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer",
}));

export const DetailsAndNotesEventsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: 26,
  paddingBottom: 60,
}));

export const ProjectImageModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
}));

export const AssigneeListModalContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  width: "auto",
  height: "auto",
  maxHeight: 500,
  flexDirection: "column",
  backgroundColor: Colors.white,
  overflow: "scroll",
  alignSelf: "center",
}));

export const ButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
}));

export const SiteVisitTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
  margin: 24,
  marginRight: 0,
  marginLeft: 0,
  marginBottom: 16,
}));

export const SiteVisitUpdateModal = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  flexDirection: "column",
  paddingTop: 15,
  borderRadius: 5,
}));

export const BottomButtonContainer = styled(Box)(({ theme }) => ({
  background: Colors.white,
  boxShadow: "0px 1px 20px rgba(0, 0, 0, 0.12)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: 30,
  paddingTop: 15,
  paddingBottom: 15,
  margin: 25,
}));

export const ModifySalesStageModal = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.white,
  display: "flex",
  flexDirection: "column",
  borderRadius: 5,
  width: "100%",
}));

export const TitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 25,
  paddingRight: 25,
  margin: 24,
  marginRight: 0,
  marginLeft: 0,
  marginBottom: 0,
}));
export const RowStyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  width: "100%",
  padding: 25,
  margin: 0,
  paddingTop: 0,
  justifyContent: "space-between",
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.greyColor}`,
  fontSize: 16,
  color: Colors.titleColor,
  fontFamily: "Lora-Bold",
  [theme.breakpoints.down("xl")]: {
    fontSize: 12,
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: 10,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 8,
  },
}));

export const CommentAndNotesCard = styled(Card)(({ theme }) => ({
  borderRadius: "16px 16px 0px 0px",
  boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
  width: "30%",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    width: "50%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: 25,
  },
}));

export const OpportunityDetailsAndComments = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

export const TitleAndButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));
export const RowStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 20,
}));

export const ColumnStyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export const PreSalesSiteVisitCard = styled(Card)(({ theme }) => ({
  borderRadius: 5,
  boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
  width: "93%",
  display: "flex",
  flexDirection: "column",
  padding: 25,
  paddingLeft: 35,
  marginTop: 10,
  [theme.breakpoints.down("lg")]: {
    display: "flex",
    flexDirection: "column",
    width: "85%",
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

export const MessageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: Colors.lightDarkGrey,
  borderRadius: 8,
  [theme.breakpoints.down("md")]: {
    width: "85%",
  },
  [theme.breakpoints.down("lg")]: {
    width: "85%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
}));

export const ConvertAgainButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: 25,
}));
