import { Card, CardContent, Container } from "@mui/material";
import moment from "moment";
import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ActionMeta } from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { useGetEmploymentAtListQuery } from "../../../features/api/api-slice";
import {
  useCreateOpportunityMutation,
  useGetChannelPartnerListQuery,
  useGetOpportunityDetailsQuery,
  useGetOpportunitySourceListQuery,
  useGetOpportunitySubSourceListQuery,
  useGetStatusListQuery,
  useUpdateOpportunityMutation,
} from "../../../features/api/opportunity-api-slice";
import CustomizedDialogs from "../../components/customized-dialog";
import DatePickerComponent from "../../components/date-picker";
import Dropdown from "../../components/dropdown";
import Layout from "../../components/layout";
import MaterialModal from "../../components/modal";
import NavigationPath from "../../components/navigation-path";
import ProjectAndUnitPreference, {
  ProjectDetailsType,
} from "../../components/project-and-unit-preference";
import RowRadioButtonsGroup from "../../components/radio-button";
import {
  CustomizedContainer,
  CustomizedDivider,
  CustomizedText,
  SolidButton,
} from "../../components/styled-componnets";
import TextInput from "../../components/text-input/text-input";
import { API_CONSTANTS } from "../../constants";
import { OpportunityEditableStatuses, Status } from "../../constants/statuses";
import {
  useGetUserDetailsHooks,
  useRedirectToLogin,
  useScreenSizeApplier,
} from "../../hooks";
import { BasicDetailsTypes } from "../../utils/booking-utils";
import { YYYY_MM_DD_WITH_HYPHEN } from "../../utils/date";
import { isEmailValid, isPhoneNumberValid } from "../../utils/functions";
import Colors from "../../utils/theme/color";
import ApplicantBasicDetailsForm from "../create-booking/applicant-basic-details-form";
import ExistingOrCustomerReferral, {
  EMPTY_EXISTING_CUSTOMER_DETAILS,
  ExistingCustomerProps,
} from "./component/existing-or-customer-referral";
import "./create-opportunity.css";
import { AssigneeModalContainer } from "./styled-component";

const PROJECT_BUDGET_RANGE = [
  { id: "1 Cr to 1.5 Cr", label: "1 Cr to 1.5 Cr" },
  { id: "1.5 Cr to 2 Cr", label: "1.5 Cr to 2 Cr" },
  { id: "2 Cr to 2.5 Cr", label: "2 Cr to 2.5 Cr" },
  { id: "2.5 Cr and above", label: "2.5 Cr and above" },
];
const OPPORTUNITIES = "OPPORTUNITIES";
export const EXISTING_CUSTOMER = "Existing Customer";
export const CUSTOMER_REFERRAL = "Customer Referral";
export const WALK_IN = "Walk In";
export const CHANNEL_PARTNER = "Channel Partner";

const dropdownContainer = {
  m: 0,
  display: "flex",
  pb: 2,
  alignItems: "center",
  width: "47.5%",
};
const ERROR = "error";

const salesAssignedModalProps = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const CreateOpportunity = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { getUserToken, isCurrentUserGRE, isCurrentUserCP } = useGetUserDetailsHooks();

  const { mergeStyles } = useScreenSizeApplier();
  const { redirectToLoginBasedOnResponse } = useRedirectToLogin();
  let navigate = useNavigate();

  const [date, setDate] = useState(
    moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)
  );
  const [dateErrorMsg, setDateErrorMsg] = useState("");

  const [employedAt, setEmployedAt] = useState("");
  const [budget, setBudget] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedSubSource, setSelectedSubSource] = useState("");
  const [location, setLocation] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedChannelPartnerName, setSelectedChannelPartnerName] = useState({
    name: "", 
    title: "", 
  });

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSalesAssignee, setShowSalesAssignee] = useState(false);
  const [isSubSourceListLoading, setIsSubSourceListLoading] = useState(false);

  const [employedAtList, setEmployedAtList] = useState([]);
  const [sourceList, setSourceList] = useState([] as any);
  const [subSourceList, setSubSourceList] = useState([]);
  const [statusList, setStatusList] = useState<Status[]>([]);
  const [selectedChannelParterNameList, setSelectedChannelParterNameList] = useState([]);

  const [personalInformation, setPersonalInformation] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    emailErrorMsg: "",
    mobileErrorMsg: "",
  } as any);
  const [projectAndUnitPreference, setProjectAndUnitPreference] = useState({
    project: "",
    unitType: "",
    unit: "",
  } as any);

  const [existingCustomer, setExistingCustomer] =
    useState<ExistingCustomerProps>(EMPTY_EXISTING_CUSTOMER_DETAILS);
  const [opportunityId, setOpportunityId] = useState(params?.id);
  const personalInformationRef = useRef(personalInformation);
  const projectAndUnitPreferenceRef = useRef(projectAndUnitPreference);
  const [isOpportunityLoading, setIsOpportunityLoading] = useState(false);
  const existingCustomerInformationRef = useRef(existingCustomer);

  const {
    data: getEmployedAtList,
    isSuccess: isGetEmployedAtListSuccess,
    isError: isGetEmployedAtListFailed,
  } = useGetEmploymentAtListQuery({});

  const [
    createOpportunity,
    {
      isLoading: isCreateOpportunityLoading,
      isSuccess: isCreateOpportunitySuccess,
      isError: isCreateOpportunityFailed,
      data: createOpportunityResponse,
      error: createOpportunityError,
    },
  ] = useCreateOpportunityMutation();

  const [
    updateOpportunity,
    {
      isLoading: isUpdateOpportunityLoading,
      isSuccess: isUpdateOpportunitySuccess,
      isError: isUpdateOpportunityFailed,
      data: updateOpportunityResponse,
      error: updateOpportunityError,
    },
  ] = useUpdateOpportunityMutation();

  const {
    data: getOpportunityDetailsResponse,
    isSuccess: isGetOpportunityDetailsSuccess,
    isError: isGetOpportunityDetailsFailed,
    refetch: refetchOpportunityDetails,
  } = useGetOpportunityDetailsQuery(
    {
      opportunityId,
      userToken: getUserToken(),
    },
    { skip: !Boolean(opportunityId) }
  );

  const {
    data: getSourceListResponse,
    isSuccess: isGetSourceListSuccess,
    isError: isGetSourceListFailed,
    error: getSourceListError,
  } = useGetOpportunitySourceListQuery({
    userToken: getUserToken(),
  });

  const {
    data: getSubSourceListResponse,
    isSuccess: isGetSubSourceListSuccess,
    isError: isGetSubSourceListFailed,
    error: getSubSourceListError,
    refetch: refetchSubSource,
  } = useGetOpportunitySubSourceListQuery(
    {
      userToken: getUserToken(),
      source: selectedSource,
    },
    { skip: !Boolean(selectedSource) }
  );

  const {
    data: getStatusListResponse,
    isSuccess: isGetStatusListSuccess,
    isError: isGetStatusListFailed,
    error: getStatusListError,
    refetch: refetchStatusListBasedOnCurrentStatus,
  } = useGetStatusListQuery({
    userToken: getUserToken(),
    currentStatus: selectedStatus,
  });
  const {
    data: getChannelPartnerListResponse,
    isSuccess: isGetChannelPartnerListSuccess,
    isError: isGetChannelPartnerListFailed,
    refetch: refetchChannelPartnerList,
  } = useGetChannelPartnerListQuery({
    userToken: getUserToken(),
  });
  
  const navigateToOpportunities = useCallback(() => {
    navigate(`/${OPPORTUNITIES}`);
  }, [navigate]);

  const navigateToDetails = useCallback(() => {
    navigate(`/opportunity/${opportunityId}`);
  }, [navigate, opportunityId]);

  useEffect(() => {
    if (Boolean(opportunityId)) {
      refetchOpportunityDetails();
    }
    if (Boolean(selectedSource)) {
      refetchSubSource();
    }
    refetchStatusListBasedOnCurrentStatus();
  }, [
    opportunityId,
    refetchOpportunityDetails,
    refetchSubSource,
    selectedSource,
    refetchStatusListBasedOnCurrentStatus,
    refetchChannelPartnerList,
  ]);

  useEffect(() => {
    if (isGetEmployedAtListSuccess) {
      if (
        getEmployedAtList?.data?.length &&
        getEmployedAtList?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setEmployedAtList(getEmployedAtList?.data);
      } else if (getEmployedAtList?.message) {
        toast(getEmployedAtList?.message, {
          type: "error",
        });
        setEmployedAtList([]);
      }
    } else if (isGetEmployedAtListFailed) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
      setEmployedAtList([]);
    }
  }, [
    getEmployedAtList,
    isGetEmployedAtListSuccess,
    isGetEmployedAtListFailed,
    t,
  ]);

  useEffect(() => {
    if (isGetChannelPartnerListSuccess) {
      if (
        getChannelPartnerListResponse?.data?.length &&
        getChannelPartnerListResponse?.status_code === API_CONSTANTS.successStatusCode
      ) {
        setSelectedChannelParterNameList(getChannelPartnerListResponse?.data);
      } else if (getChannelPartnerListResponse?.message) {
        toast(getChannelPartnerListResponse?.message, {
          type: "error",
        });
        setSelectedChannelParterNameList([]);
      }
    } else if (isGetChannelPartnerListFailed) {
      toast(t("error_messages.server_error"), {
        type: "error",
      });
      setSelectedChannelParterNameList([]);
    }
  }, [
    getChannelPartnerListResponse,
    isGetChannelPartnerListSuccess,
    isGetChannelPartnerListFailed,
    t,
  ]);

  useEffect(() => {
    if (isOpportunityLoading) {
      if (isCreateOpportunitySuccess) {
        if (
          createOpportunityResponse?.status_code ===
          API_CONSTANTS.successStatusCode
        ) {
          toast(
            createOpportunityResponse?.message ??
              t("create_opportunity.create_opportunity_success"),
            {
              type: "success",
            }
          );
          setIsCreateButtonDisabled(true);
          setOpportunityId(createOpportunityResponse?.data);
          setIsOpportunityLoading(false);
          setShowSalesAssignee(!showSalesAssignee);
        } else if (createOpportunityResponse?.message) {
          setIsCreateButtonDisabled(false);
          toast(
            createOpportunityResponse?.message ??
              t("error_messages.server_error"),
            { type: "error" }
          );
          setIsOpportunityLoading(false);
        }
      } else if (isCreateOpportunityFailed) {
        setIsCreateButtonDisabled(false);
        redirectToLoginBasedOnResponse(
          createOpportunityError,
          createOpportunityResponse
        );
        setIsOpportunityLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCreateOpportunitySuccess,
    isCreateOpportunityFailed,
    createOpportunityResponse,
    t,
    setIsCreateButtonDisabled,
    navigate,
    createOpportunityError,
    isOpportunityLoading,
    showSalesAssignee,
  ]);

  useEffect(() => {
    if (isOpportunityLoading) {
      if (isUpdateOpportunitySuccess) {
        if (
          updateOpportunityResponse?.data?.name &&
          updateOpportunityResponse?.status_code ===
            API_CONSTANTS.successStatusCode
        ) {
          toast(updateOpportunityResponse?.message, {
            type: "success",
          });
          setIsCreateButtonDisabled(true);
          setOpportunityId(updateOpportunityResponse?.data?.name);
          navigateToDetails();
          setIsOpportunityLoading(false);
        } else if (updateOpportunityResponse?.message) {
          setIsCreateButtonDisabled(false);
          toast(updateOpportunityResponse?.message, { type: "error" });
          setIsOpportunityLoading(false);
        }
      } else if (isUpdateOpportunityFailed) {
        setIsCreateButtonDisabled(false);
        redirectToLoginBasedOnResponse(
          updateOpportunityError,
          updateOpportunityResponse
        );
        setIsOpportunityLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUpdateOpportunitySuccess,
    isUpdateOpportunityFailed,
    updateOpportunityResponse,
    t,
    setIsCreateButtonDisabled,
    navigate,
    updateOpportunityError,
    navigateToDetails,
    isOpportunityLoading,
  ]);

  useEffect(() => {
    if (isGetSourceListSuccess) {
      if (Array.isArray(getSourceListResponse?.data)) {
        setSourceList(getSourceListResponse?.data);
      } else if (getSourceListResponse?.message) {
        toast(getSourceListResponse?.message, {
          type: "error",
        });
        setSourceList([]);
      }
    } else if (isGetSourceListFailed) {
      redirectToLoginBasedOnResponse(getSourceListError, getSourceListResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getSourceListResponse,
    isGetSourceListSuccess,
    isGetSourceListFailed,
    t,
    getSourceListError,
  ]);

  useEffect(() => {
    if (isSubSourceListLoading) {
      if (isGetSubSourceListSuccess) {
        if (Array.isArray(getSubSourceListResponse?.data)) {
          setSubSourceList(getSubSourceListResponse?.data);
        } else if (getSubSourceListResponse?.message) {
          toast(getSubSourceListResponse?.message, {
            type: "error",
          });
          setSubSourceList([]);
        }
        setIsSubSourceListLoading(false);
      } else if (isGetSubSourceListFailed) {
        redirectToLoginBasedOnResponse(
          getSubSourceListError,
          getSubSourceListResponse
        );
        setIsSubSourceListLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getSubSourceListResponse,
    isGetSubSourceListSuccess,
    isGetSubSourceListFailed,
    t,
    getSubSourceListError,
    isSubSourceListLoading,
  ]);

  const isOpportunityEditable = useMemo(
    () =>
      getOpportunityDetailsResponse?.data?.status
        ? OpportunityEditableStatuses.includes(
            getOpportunityDetailsResponse?.data?.status
          )
        : true,
    [getOpportunityDetailsResponse?.data?.status]
  );

  useEffect(() => {
    if (isGetStatusListSuccess) {
      if (Array.isArray(getStatusListResponse?.data)) {
        const filterStatuses = getStatusListResponse?.data?.filter(
          (status: Status) =>
            status !== Status.LOST && status !== Status.CONVERTED
        );
        setStatusList(filterStatuses);
      } else if (getStatusListResponse?.message) {
        toast(getStatusListResponse?.message, {
          type: "error",
        });
      }
    } else if (isGetStatusListFailed) {
      redirectToLoginBasedOnResponse(getStatusListError, getStatusListResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getStatusListResponse,
    isGetStatusListSuccess,
    isGetStatusListFailed,
    t,
    getStatusListError,
    isOpportunityEditable,
  ]);



  const isSourceExistingOrReferralCustomer = useCallback(
    () =>
      selectedSource === EXISTING_CUSTOMER ||
      selectedSource === CUSTOMER_REFERRAL ||
      selectedSubSource === EXISTING_CUSTOMER ||
      selectedSubSource === CUSTOMER_REFERRAL,
    [selectedSource, selectedSubSource]
  );

  useEffect(() => {
    if (
      personalInformationRef.current?.firstName &&
      personalInformationRef.current?.lastName &&
      personalInformationRef.current?.mobile &&
      personalInformationRef.current?.email &&
      projectAndUnitPreferenceRef.current.project &&
      date &&
      selectedSource
    ) {
      if (isSourceExistingOrReferralCustomer()) {
        if (existingCustomer?.mobile) setIsCreateButtonDisabled(false);
        else setIsCreateButtonDisabled(true && isCurrentUserGRE);
      } else {
        setIsCreateButtonDisabled(false);
      }
    } else {
      setIsCreateButtonDisabled(true && isCurrentUserGRE);
    }
  }, [
    personalInformationRef.current.firstName,
    personalInformationRef.current.lastName,
    personalInformationRef.current.mobile,
    personalInformationRef.current.email,
    projectAndUnitPreferenceRef.current.project,
    setIsCreateButtonDisabled,
    isCurrentUserGRE,
    date,
    selectedSource,
    isSourceExistingOrReferralCustomer,
    existingCustomer?.mobile,
  ]);

  useEffect(() => {
    personalInformationRef.current = personalInformation;
  }, [personalInformation]);

  useEffect(() => {
    if (isGetOpportunityDetailsSuccess) {
      if (
        getOpportunityDetailsResponse?.data &&
        getOpportunityDetailsResponse.status_code ===
          API_CONSTANTS.successStatusCode
      ) {
        let currentPersonalInfo = personalInformationRef?.current;
        let currentProjectAndUnitInfo = projectAndUnitPreferenceRef?.current;
        let existingCustomerInfo = existingCustomerInformationRef?.current;
        currentPersonalInfo.firstName =
          getOpportunityDetailsResponse?.data?.first_name;
        currentPersonalInfo.lastName =
          getOpportunityDetailsResponse?.data?.last_name;
        currentPersonalInfo.mobile =
          getOpportunityDetailsResponse?.data?.contact_mobile;
        currentPersonalInfo.email =
          getOpportunityDetailsResponse?.data?.contact_email;
        currentProjectAndUnitInfo.project =
          getOpportunityDetailsResponse?.data?.project;
        currentProjectAndUnitInfo.unit =
          getOpportunityDetailsResponse?.data?.unit;
        currentProjectAndUnitInfo.unitType =
          getOpportunityDetailsResponse?.data?.unit_type;
        setBudget(getOpportunityDetailsResponse?.data.budget_range);
        setDate(getOpportunityDetailsResponse?.data?.transaction_date);
        setEmployedAt(getOpportunityDetailsResponse?.data?.employed_at);
        setSelectedSource(getOpportunityDetailsResponse?.data?.source);
        setSelectedSubSource(getOpportunityDetailsResponse?.data?.sub_source);
        setLocation(getOpportunityDetailsResponse?.data?.location);
        existingCustomerInfo.id =
          getOpportunityDetailsResponse?.data?.existing_customer;
        existingCustomerInfo.name =
          getOpportunityDetailsResponse?.data?.site_visit_customer;
        existingCustomerInfo.mobile =
          getOpportunityDetailsResponse?.data?.existing_customer_mobile;
        setSelectedStatus(getOpportunityDetailsResponse?.data?.status);
      } else if (getOpportunityDetailsResponse?.message) {
        toast(getOpportunityDetailsResponse?.message, {
          type: ERROR,
        });
      }
    } else if (isGetOpportunityDetailsFailed) {
      toast(t("error_messages.server_error"), {
        type: ERROR,
      });
    }
  }, [
    getOpportunityDetailsResponse,
    isGetOpportunityDetailsSuccess,
    isGetOpportunityDetailsFailed,
    t,
  ]);

  useEffect(() => {
    if (isCreateOpportunityLoading) {
      setIsCreateButtonDisabled(true);
    }
    if (isUpdateOpportunityLoading) {
      setIsCreateButtonDisabled(true);
    }
  }, [
    isCreateOpportunityLoading,
    setIsCreateButtonDisabled,
    isUpdateOpportunityLoading,
  ]);

  useEffect(() => {
    if (!date) {
      setDateErrorMsg(t("create_opportunity.invalid_date"));
      setIsCreateButtonDisabled(true);
    }
  }, [date, t]);

  useEffect(() => {
    if (selectedSource) {
      setIsSubSourceListLoading(true);
    }
  }, [selectedSource]);

  const onChangeDate = (e: any) => {
    setDate(e.target.value);
    setDateErrorMsg("");
  };

  const updatePersonalInformation = (
    details:
      | SetStateAction<{
          firstName: string;
          lastName: string;
          mobile: string;
          email: string;
        }>
      | BasicDetailsTypes,
    index: number
  ) => {
    setPersonalInformation(details);
    personalInformationRef.current = details;
  };

  const onUpdateExistingCustomerDetails = (details: ExistingCustomerProps) => {
    setExistingCustomer(details);
    existingCustomerInformationRef.current = details;
  };

  const onChangeValue = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>,
    callBack: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }
  ) => {
    callBack(newValue?.label);
  };

  const onChangeSource = (
    newValue: any,
    actionMeta: ActionMeta<{
      value: string;
      label: string;
    }>
  ) => {
    setSelectedSource(newValue?.label);
    setSubSourceList([]);
    setSelectedSubSource("");
  };

  const onChangeBudget = (
    e: ChangeEvent<HTMLInputElement>,
    callBack: {
      (value: SetStateAction<string>): void;
      (value: SetStateAction<string>): void;
      (arg0: string): void;
    }
  ) => {
    callBack(e.target.value);
  };

  const onUpdateProjectAndUnitPreference = (
    details: SetStateAction<ProjectDetailsType>
  ) => {
    setProjectAndUnitPreference(details);
    projectAndUnitPreferenceRef.current = details;
  };

  const toggleDropDown = useCallback((isOpened: boolean) => {
    setDropDownOpen(isOpened);
  }, []);

  const onPressCreateOpportunity = async () => {
    if (
      isPhoneNumberValid(personalInformationRef.current?.mobile) &&
      isEmailValid(personalInformationRef.current?.email)
    ) {
      setIsOpportunityLoading(true);
      personalInformationRef.current.mobileErrorMsg = "";
      personalInformationRef.current.emailErrorMsg = "";
      if (Boolean(opportunityId)) {
        let opportunityDetails = getOpportunityDetailsResponse?.data;
        let payload = {
          ...opportunityDetails,
          ...{
            name: opportunityId ? opportunityId : "",
            transaction_date: date,
            contact_email: personalInformationRef.current?.email,
            first_name: personalInformationRef.current?.firstName,
            last_name: personalInformationRef.current?.lastName,
            contact_mobile: personalInformationRef.current?.mobile,
            project: projectAndUnitPreferenceRef.current.project,
            unit_type: projectAndUnitPreferenceRef.current.unitType,
            unit: projectAndUnitPreferenceRef.current.unit,
            budget_range: budget,
            employed_at: employedAt,
            source: selectedSource,
            location,
            ...(selectedSubSource && {
              sub_source: `${selectedSource}-${selectedSubSource}`,
            }),
            existing_customer: existingCustomerInformationRef?.current.id,
            site_visit_customer: existingCustomerInformationRef?.current.name,
            existing_customer_mobile:
              existingCustomerInformationRef?.current.mobile,
            status: selectedStatus,
            custom_external_channel_partner:selectedChannelPartnerName.name
          },
        };  
        console.log('update payload',payload);
              
        await updateOpportunity({
          payload,
          userToken: getUserToken(),
        }).unwrap();
      } else {
        let payload = {
          email: personalInformationRef.current?.email,
          first_name: personalInformationRef.current?.firstName,
          last_name: personalInformationRef.current?.lastName,
          mobile: personalInformationRef.current?.mobile,
          project: projectAndUnitPreferenceRef.current.project,
          unit_type: projectAndUnitPreferenceRef.current.unitType,
          unit: projectAndUnitPreferenceRef.current.unit,
          budget_range: budget,
          employed_at: employedAt,
          source: selectedSource,
          location,
          ...(selectedSubSource && {
            sub_source: `${selectedSubSource}-${selectedSource}`,
          }),
          existing_customer: existingCustomerInformationRef?.current.id,
          site_visit_customer: existingCustomerInformationRef?.current.name,
          existing_customer_mobile:
            existingCustomerInformationRef?.current.mobile,
          status: selectedStatus,
          custom_external_channel_partner:selectedChannelPartnerName.name
        };
        console.log('create payload',payload);

        await createOpportunity({
          payload,
          userToken: getUserToken(),
        }).unwrap();
      }
    } else {
      setIsCreateButtonDisabled(true);
      if (!isPhoneNumberValid(personalInformationRef.current?.mobile)) {
        personalInformationRef.current.mobileErrorMsg = t(
          "create_opportunity.invalid_mobile"
        );
        toast(t("create_opportunity.invalid_mobile"), {
          type: "error",
        });
      }
      if (!isEmailValid(personalInformationRef.current?.email)) {
        personalInformationRef.current.emailErrorMsg = t(
          "create_opportunity.invalid_email"
        );
        toast(t("create_opportunity.invalid_email"), {
          type: "error",
        });
      }
    }
  };

  const onClickDiscard = () => {
    toggleDiscardModal();
  };

  const onClickCancel = () => {
    toggleDiscardModal();
  };

  const onClickOkay = () => {
    navigate("../");
  };

  const toggleDiscardModal = () => setShowDiscardModal(!showDiscardModal);

  const onChangeLocation = (e: ChangeEvent<HTMLInputElement>) =>
    setLocation(e.target.value);

  const returnLabelAndValue = (
    list: {
      name: string;
      industry: string;
      sub_source: string;
    }[]
  ) =>
    list?.map(
      (item: { name: string; industry: string; sub_source: string,  }) => ({
        value: item.name ?? item.industry ?? item?.sub_source,
        label:  item?.name ?? item.industry ?? item?.sub_source ,
      })
    );

  const formattedStatusList = useMemo(
    () =>
      statusList?.map((item: string) => ({
        value: item,
        label: item,
      })),
    [statusList]
  );

  const renderSourceAndDate = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { ...dropdownContainer, ...{ width: "100%", pb: 0 } },
        tabletStyles: { width: "100%" },
      })}
    >
      <Dropdown
        outerLabel={t("create_opportunity.source")}
        markRequiredField={true && isCurrentUserGRE}
        dropdownItems={returnLabelAndValue(sourceList)}
        inputLabel={t("create_opportunity.employed_at_placeholder")}
        value={selectedSource}
        handleChange={onChangeSource}
        setIsDropdownOpen={toggleDropDown}
        isDisabled={!isCurrentUserGRE || !isOpportunityEditable}
      />
      <div style={{ width: "10%" }} />
      <DatePickerComponent
        date={date}
        onChangeDate={onChangeDate}
        outerLabel={t("create_opportunity.date")}
        markRequiredField={false}
        helperText={dateErrorMsg}
        error={Boolean(dateErrorMsg)}
        styles={{
          textInputContainer: {
            ...(dateErrorMsg && {
              marginTop: 30,
            }),
          },
        }}
        maxDate={moment(new Date()).format(YYYY_MM_DD_WITH_HYPHEN)}
        disabled={true}
      />
    </Container>
  );

  const renderEmployedAt = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: { ...dropdownContainer, ...{ width: "100%", pb: 0 } },
        tabletStyles: { width: "100%" },
      })}
    >
      <TextInput
        id="location"
        value={location}
        label={!location ? t("create_opportunity.location_placeholder") : ""}
        onChange={onChangeLocation}
        outerLabel={t("create_opportunity.location")}
        markRequiredField={false}
        disabled={!isOpportunityEditable}
      />
      <div style={{ width: "10%" }} />
      <Dropdown
        outerLabel={t("create_opportunity.employed_at")}
        markRequiredField={false}
        dropdownItems={returnLabelAndValue(employedAtList)}
        inputLabel={t("create_opportunity.employed_at_placeholder")}
        value={employedAt}
        handleChange={(newValue, metaValue) =>
          onChangeValue(newValue, metaValue, setEmployedAt)
        }
        setIsDropdownOpen={toggleDropDown}
        isDisabled={!isOpportunityEditable}
      />
    </Container>
  );
  const renderPersonalInformationForm = () => (
    <Container disableGutters>
      <CustomizedDivider width={"100%"} />
      <CustomizedText fontSize={24} marginBottom={0.2}>
        {t("create_opportunity.personal_information")}
      </CustomizedText>
      <Container disableGutters>
        <ApplicantBasicDetailsForm
          basicData={personalInformationRef.current}
          onUpdate={(details) => updatePersonalInformation(details, 0)}
          isGenderRequired={false}
          isFatherNameRequired={false}
          isDOBRequired={false}
          isAddressRequired={false}
          isPhotoRequired={false}
          isEmailMandatory={true}
          styles={{
            textInputRowContainer: { width: "100%" },
          }}
          isEditable={isOpportunityEditable}
        />
        {renderEmployedAt()}
      </Container>
    </Container>
  );

  const renderProjectAndUnitPreferences = () => (
    <Container disableGutters sx={{ pb: "2rem" }}>
      <CustomizedDivider width={"100%"} />
      <ProjectAndUnitPreference
        projectDetails={projectAndUnitPreferenceRef.current}
        onUpdate={onUpdateProjectAndUnitPreference}
        toggleDropDown={toggleDropDown}
        isProjectsAndUnitsEditable={isOpportunityEditable}
      />
    </Container>
  );

  const renderBudget = () => (
    <RowRadioButtonsGroup
      title={t("create_opportunity.Budget")}
      labels={PROJECT_BUDGET_RANGE}
      value={budget}
      handleChange={(e) => onChangeBudget(e, setBudget)}
      markRequiredField={false}
      disabled={!isOpportunityEditable}
    />
  );

  const renderContent = () => (
    <div className="create-opportunity-container">
      <CustomizedContainer maxWidth={"md"} sx={{ mt: 5 }}>
        <NavigationPath
          initialPath={t("app_common.dashboard")}
          forwardPath={
            Boolean(opportunityId)
              ? t("create_opportunity.update_opportunity")
              : t("create_opportunity.create_opportunity")
          }
          onClick={navigateToOpportunities}
        />
        <CustomizedText fontSize={36} gutterBottom>
          {Boolean(opportunityId)
            ? t("create_opportunity.update_opportunity")
            : t("create_opportunity.title")}
        </CustomizedText>
        <Card
          sx={{
            borderRadius: 3,
            boxShadow: "0px 3px 12px -1px rgba(0, 0, 0, 0.16)",
          }}
        >
          <CardContent sx={{ pr: 5, pl: 5 }}>
            {renderSourceAndDate()}
            {renderSubSource()}
            {isSourceExistingOrReferralCustomer() &&
              renderReferralOrExistingCustomer()}
            {renderPersonalInformationForm()}
            {renderProjectAndUnitPreferences()}
            <Container disableGutters maxWidth={"sm"} sx={{ m: 0 }}>
              {renderBudget()}
            </Container>
          </CardContent>
        </Card>
      </CustomizedContainer>
    </div>
  );

  const renderSalesAssignee = () => (
    <AssigneeModalContainer width={"30%"} sx={{ pt: 2 }}>
      <CustomizedText
        fontSize={24}
        fontFamily={"Lato-Regular"}
        sx={{ textAlign: "center" }}
      >
        {t("create_opportunity.opportunity_assigned_text")}
      </CustomizedText>
      <CustomizedText
        fontSize={24}
        sx={{ textAlign: "center", fontWeight: 600 }}
        fontColor={Colors.primaryColor}
      >
        {getOpportunityDetailsResponse?.data?.allocated_sales_team}
      </CustomizedText>
      <SolidButton
        sx={{ pt: 0.5, pb: 0.5, m: 2 }}
        fontSize={20}
        onClick={navigateToDetails}
      >
        {t("app_common.okay")}
      </SolidButton>
    </AssigneeModalContainer>
  );

  const renderSubSource = () => (
    <Container
      disableGutters
      sx={mergeStyles({
        styles: {
          ...dropdownContainer,
          ...(!isCurrentUserGRE && { width: "100%", pb: 0}),
        },
        tabletStyles: { width: "100%" },
      })}
    >
      {Boolean(subSourceList?.length) && (
        <>
          <Dropdown
            outerLabel={t("create_opportunity.sub_source")}
            markRequiredField={false}
            dropdownItems={returnLabelAndValue(subSourceList)}
            inputLabel={t("create_opportunity.sub_source_placeholder")}
            value={selectedSubSource}
            handleChange={(newValue, metaValue) =>
              onChangeValue(newValue, metaValue, setSelectedSubSource)
            }
            setIsDropdownOpen={toggleDropDown}
            isDisabled={!isCurrentUserGRE || !isOpportunityEditable}
          />
          <div style={{ width: "10%" }} />
        </>
      )}
        {(!isCurrentUserGRE || !isCurrentUserCP ) && (
        <Dropdown
          outerLabel={t("create_opportunity.status")}
          markRequiredField={false}
          dropdownItems={formattedStatusList}
          inputLabel={t("create_opportunity.sub_source_placeholder")}
          value={selectedStatus}
          handleChange={(newValue, metaValue) =>
            onChangeValue(newValue, metaValue, setSelectedStatus)
          }
          setIsDropdownOpen={toggleDropDown}
          isDisabled={isCurrentUserGRE  || selectedStatus === Status.CONVERTED}
        />
      )}
       <div style={{ width: "10%" }} />
       {isCurrentUserCP && (
        <Dropdown
        outerLabel={t("create_opportunity.custom_external_channel_partner")}
        markRequiredField={true}
        dropdownItems={
          getChannelPartnerListResponse?.data.map((item: { name: string; title: string }) => ({
            value: item.name,
            label: item.title,
          })) || []
        }
        inputLabel={t("create_opportunity.custom_external_channel_partner")}
        value={selectedChannelPartnerName.title}
        handleChange={(selectedOption) => {
          if (selectedOption) {
            setSelectedChannelPartnerName({
              name: selectedOption.value,
              title: selectedOption.label,
            });
          }
        }}
        setIsDropdownOpen={toggleDropDown}
      />
      )}

    </Container>
  );

  

  const renderReferralOrExistingCustomer = () => (
    <Container disableGutters>
      <CustomizedDivider width={"100%"} />
      <CustomizedText fontSize={24} marginBottom={0.2}>
        {`${selectedSource}`}
        {selectedSource === WALK_IN && ` - ${selectedSubSource}`}
      </CustomizedText>
      <Container disableGutters>
        <ExistingOrCustomerReferral
          source={selectedSource}
          subSource={selectedSubSource}
          toggleDropDown={toggleDropDown}
          dropdownContainer={dropdownContainer}
          existingCustomer={existingCustomerInformationRef?.current}
          onUpdate={onUpdateExistingCustomerDetails}
          isDisabled={!isOpportunityEditable}
        />
      </Container>
    </Container>
  );

  return (
    <>
      <Layout
        children={renderContent()}
        rightButtonTitle={
          Boolean(opportunityId)
            ? t("create_opportunity.save_changes")
            : t("create_opportunity.create_opportunity")
        }
        leftButtonTitle={t("create_opportunity.discard")}
        hideBottomBar={dropdownOpen || selectedStatus === Status.CONVERTED}
        onPressButton={onPressCreateOpportunity}
        isButtonDisabled={isCreateButtonDisabled}
        onClickLeftButton={onClickDiscard}
        styles={{
          buttonContainer: mergeStyles({
            styles: { width: "45%", justifyContent: "flex-end" },
            belowXlStyles: {
              width: "59%",
            },
            belowLargeStyles: {
              width: "83%",
            },
            tabletStyles: {
              width: "94%",
            },
          }),
        }}
        isRightButtonRequired={true}
        isRightButtonLoading={isOpportunityLoading}
      />
      <CustomizedDialogs
        handleOpenAndClose={onClickCancel}
        open={showDiscardModal}
        subTitle={t("create_opportunity.discard")}
        description={t("create_opportunity.discard_description")}
        primaryButtonTitle={t("app_common.yes")}
        secondaryButtonTitle={t("app_common.no")}
        onClickSecondaryButton={onClickCancel}
        onClickPrimaryButton={onClickOkay}
      />
      {Boolean(getOpportunityDetailsResponse?.data?.allocated_sales_team) && (
        <MaterialModal
          children={renderSalesAssignee()}
          sx={salesAssignedModalProps}
          open={showSalesAssignee}
          onClose={() => setShowSalesAssignee(true)}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default CreateOpportunity;
