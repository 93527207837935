import { apiSlice } from "./api-slice";
import {
  getListByNameOrPhoneNumber,
  getListByStatus,
} from "../../app/utils/functions";

const getOpportunityListByProjects = (projects: string[]) =>
  projects?.length ? `&project=${projects}` : "";

export const OpportunityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOpportunityList: builder.query({
      query: ({
        paginationInfo,
        selectedStatus,
        selectedProjects,
        searchText,
        userToken,
      }) => ({
        url: `bren_erp.v8.api.get_opportunities?page=${
          paginationInfo.page
        }&size=${paginationInfo.size}${getListByStatus(
          selectedStatus
        )}${getOpportunityListByProjects(
          selectedProjects
        )}${getListByNameOrPhoneNumber(searchText)}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
      providesTags: ["opportunity"],
    }),
    createOpportunity: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.api.create_site_visit_lead`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["opportunity", "unitDetails"],
    }),
    getOpportunityDetails: builder.query({
      query: ({ opportunityId, userToken }) => ({
        url: `bren_erp.v8.api.get_opportunity?opportunity_id=${opportunityId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
      providesTags: ["opportunity"],
    }),
    getOpportunitySourceList: builder.query({
      query: ({ userToken }) => ({
        url: `bren_erp.v8.api.get_opportunity_source`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    updateOpportunity: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.api.update_opportunity`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["opportunity", "unitDetails"],
    }),
    getOpportunitySubSourceList: builder.query({
      query: ({ userToken, source }) => ({
        url: `bren_erp.v8.api.get_lead_sub_source?lead_source=${source}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getOpportunitySiteVisitList: builder.query({
      query: ({ opportunityId, paginationInfo, userToken }) => ({
        url: `bren_erp.v8.api.get_site_visits?opportunity_id=${opportunityId}&page=${paginationInfo.page}&size=${paginationInfo.size}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
      providesTags: ["opportunitySiteVisit"],
    }),
    addOpportunitySiteVisit: builder.mutation({
      query: ({ payload, userToken }) => ({
        url: `bren_erp.v8.api.add_site_visit`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["opportunitySiteVisit"],
    }),
    getStatusList: builder.query({
      query: ({ userToken, currentStatus }) => ({
        url: `bren_erp.v8.api.status_based_on_current_status?current_status=${currentStatus}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getChannelPartnerList: builder.query({
      query: ({ userToken }) => ({
        url: `bren_erp.v8.api.get_external_channel_partner`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    getSalesStageList: builder.query({
      query: ({ userToken }) => ({
        url: `bren_erp.v8.api.sales_stages`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    updateOpportunityComments: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.api.add_opportunity_comments`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
      invalidatesTags: ["OpportunityComments"],
    }),
    fetchOpportunityComments: builder.query({
      query: ({ userToken, opportunityId }) => ({
        url: `bren_erp.v8.api.get_opportunity_internal_comments?opportunity_id=${opportunityId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
      providesTags: ["OpportunityComments"],
    }),
    getOpportunityFollowUp: builder.query({
      query: ({ userToken, opportunityId }) => ({
        url: `bren_erp.v8.api.get_opportunity_follow_up?opportunity_id=${opportunityId}`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
      }),
    }),
    updateOpportunityFollowUp: builder.mutation({
      query: ({ userToken, payload }) => ({
        url: `bren_erp.v8.api.change_opportunity_follow_up`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `token ${userToken}`,
        },
        body: payload,
      }),
    }),
  }),
});

export const {
  useCreateOpportunityMutation,
  useGetOpportunityListQuery,
  useGetOpportunityDetailsQuery,
  useUpdateOpportunityMutation,
  useGetOpportunitySourceListQuery,
  useGetOpportunitySubSourceListQuery,
  useGetOpportunitySiteVisitListQuery,
  useAddOpportunitySiteVisitMutation,
  useGetStatusListQuery,
  useGetChannelPartnerListQuery,
  useGetSalesStageListQuery,
  useFetchOpportunityCommentsQuery,
  useUpdateOpportunityCommentsMutation,
  useGetOpportunityFollowUpQuery,
  useUpdateOpportunityFollowUpMutation,
} = OpportunityApiSlice;
